import React from "react"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./styles.module.scss"

const ShareButtons = ({ url, title, emailBody, description }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    twitterUsername,
  } = site.siteMetadata
  const meta = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    // image: `${siteUrl}${image || defaultImage}`,
    url: url || `${siteUrl}${pathname.includes("404") ? "" : pathname}`,
    card: `https://nbshare.org/card-lg.png`,
    twitterUsername,
  }

  return (
    <div>
      <EmailShareButton
        url={meta.url}
        subject={`NeighborShare · ${meta.description}`}
        body={emailBody || ""}
        className={styles.shareGrid}
        separator=" "
      >
        <EmailIcon round={true} size={40} />
      </EmailShareButton>

      <FacebookShareButton
        url={meta.url}
        quote={emailBody || meta.description}
        className={styles.shareGrid}
      >
        <FacebookIcon round={true} size={40} />
      </FacebookShareButton>

      <TwitterShareButton
        url={meta.url}
        title={`NeighborShare · ${meta.description}`}
        className={styles.shareGrid}
      >
        <TwitterIcon round={true} size={40} />
      </TwitterShareButton>

      <LinkedinShareButton
        url={meta.url}
        summary={`NeighborShare · ${meta.description}`}
        className={styles.shareGrid}
      >
        <LinkedinIcon round={true} size={40} />
      </LinkedinShareButton>
    </div>
  )
}

export default ShareButtons
const query = graphql`
  query share {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
